require("@rails/ujs").start()
require("turbolinks").start()
import "bootstrap";
import '../admin/styles/admin.scss';
import "@fortawesome/fontawesome-free/js/all";
import "controllers"; // Clipboard-Controller

require("admin/widgets/auto-submit.coffee")
require("admin/widgets/form-mark-submitting.coffee")
require("admin/widgets/init-bs-filefield.coffee")
require('admin/widgets/turbolinks-form-error-handling.coffee')
require("jquery")
window.$ = $
